var header = $('.navbar-home')

$(window).scroll(function () {
  var scroll = $(window).scrollTop()
  if (scroll >= 50) {
    header.addClass('navbar-scrolled')
  } else {
    header.removeClass('navbar-scrolled')
  }
})
