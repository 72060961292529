if (window.google == undefined) {
  const apiKey = import.meta.env.VITE_GOOGLE_API_BROWSER_KEY // Récupérez la clé API depuis les variables d'environnement Vite.js

  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${apiKey}`
  script.async = true
  script.defer = true

  document.head.appendChild(script)
}
