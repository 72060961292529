import 'packs/map'
import 'packs/webPushNotification'
import 'libs/google-maps'

import '../scrollTo'
import '../navbarBackgroundOnScroll'
import '../static_modal'
import '../app'
import '@hotwired/turbo-rails'

Turbo.session.drive = false
