function openStaticModal(e) {
  const btnModal = document.querySelector('.js-static-modal')
  if(btnModal !== null) {
    btnModal.addEventListener('click', e => {
      const appointmentModal = document.querySelector('.appointment-modal-container')
      appointmentModal.classList.add('opened')
      document.querySelector('body').classList.add('overflow--hidden');
      e.stopPropagation()
      closeStaticModal()
    })
  }
}

function closeStaticModal() {
  document.addEventListener('click', e => {
    if(e.target.matches('.appointment-modal-container__cross i') || !e.target.closest('.meetings-iframe-container') || e.target.matches('.js-static-modal')) {
      const appointmentModal = document.querySelector('.appointment-modal-container')
      appointmentModal.classList.remove('opened')
      document.querySelector('body').classList.remove('overflow--hidden');
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  openStaticModal()
})
