const mapElement = document.getElementById('map')
if (mapElement) {
  // don't try to build a map if there's no div#map to inject in
  const mapLat = parseFloat(mapElement.dataset.lat)
  const mapLng = parseFloat(mapElement.dataset.lng)
  const map = new GMaps({ el: '#map', lat: mapLat, lng: mapLng, zoom: 13 })
  const markers = JSON.parse(mapElement.dataset.markers)
  map.addMarkers(markers)

  if (markers.length === 0) {
    map.setZoom(13)
  } else if (markers.length === 1) {
    map.setCenter(markers[0].lat, markers[0].lng)
    map.setZoom(16)
  } else {
    map.fitLatLngBounds(markers)
  }
}

gmapsAutoComplete()
