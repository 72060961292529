function webpush_service_worker_registration_subscribe() {
  // Register the serviceWorker script at /serviceworker.js from your server if supported
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js').then(function (reg) {})
  }
  // Otherwise, no push notifications :(
  else {
    console.error('Service worker is not supported in this browser')
  }

  // When serviceWorker is supported, installed, and activated,
  // subscribe the pushManager property with the vapidPublicKey
  if (window.location.pathname.includes('my_notifications_page')) {
    navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      })
      // subscribe_to_webpush_notification();
    })
  }
}

function subscribe_to_webpush_notification() {
  navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
    serviceWorkerRegistration.pushManager
      .getSubscription()
      .then(subscription => {
        $.ajax({
          url: '/save_subscription',
          type: 'post',
          data: subscription.toJSON(),
          headers: {
            CSRFToken: document.querySelector('meta[name="csrf-token"]').content
          }
        })
      })
      .finally(() => console.log('Abonné aux notifications!'))
  })
}

$(document).ready(function () {
  // ask notification permission to user
  webpush_service_worker_registration_subscribe()
  if (Notification.permission === 'granted') {
    subscribe_to_webpush_notification()
  }
})
